import { ChevronRightIcon, StarIcon } from "@heroicons/react/solid";
// import logo png from assets
import logo from "../assets/logo.png";
import homepage from "../assets/homepage.png";
import { useEffect, useState } from "react";

export default function Hero() {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 1260px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 1260px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <div className="bg-white pb-8 sm:pb-12 lg:pb-12">
      <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <div>
              <img className="h-11 w-auto" src={logo} alt="Workflow" />
            </div>
            <div className="mt-20">
              <div>
                <span className="inline-flex space-x-4">
                  <span className="rounded bg-gray-100 px-2.5 py-1 text-xs font-semibold text-black-600 tracking-wide uppercase">
                    What's new
                  </span>
                  <span className="inline-flex items-center text-sm font-medium text-black-600 space-x-1">
                    <span
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: 700,
                        color: "#242424",
                      }}
                    >
                      Just shipped version 1.0
                    </span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                </span>
              </div>
              <div className="mt-6 sm:max-w-xl">
                <h1
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: 700,
                    fontSize: "2em",
                    color: "#242424",
                  }}
                >
                  {/* escape & */}
                  Fully Anonymous &#38; zero-knowledge messaging app
                </h1>
                <p
                  style={{
                    color: "#6b7280",
                    fontFamily: "Open Sans",
                    marginTop: "2%",
                  }}
                >
                  We do not store any of your data or meta-data. We do not track
                  you! Fade is the only anonymous and fully untracked messaging
                  app on planet Earth.
                </p>
              </div>
              <div className="mt-6">
                <div className="inline-flex items-center divide-x divide-gray-300">
                  <div className="flex-shrink-0 flex pr-5">
                    <StarIcon
                      className="h-5 w-5 text-black-400"
                      aria-hidden="true"
                    />
                    <StarIcon
                      className="h-5 w-5 text-black-400"
                      aria-hidden="true"
                    />
                    <StarIcon
                      className="h-5 w-5 text-black-400"
                      aria-hidden="true"
                    />
                    <StarIcon
                      className="h-5 w-5 text-black-400"
                      aria-hidden="true"
                    />
                    <StarIcon
                      className="h-5 w-5 text-black-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
                    <span style={{ color: "#242424", fontFamily: "Open Sans" }}>
                      Download on
                    </span>{" "}
                    <a
                      style={{
                        color: "#60a5fa",
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                      href="https://play.google.com/store/apps/details?id=com.fade"
                    >
                      Google Play Store
                    </a>{" "}
                    and{" "}
                    <a
                      style={{
                        color: "#60a5fa",
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                      href="https://apps.apple.com/gb/app/fade-msg/id1632043701"
                    >
                      Apple App store
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {matches && (
        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block">
              <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
              <svg
                className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={392}
                  fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                />
              </svg>
            </div>

      
              <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                <img
                  className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                  src={homepage}
                  alt=""
                />
              </div>
            
          </div>
        </div>
        )}
      </div>
    </div>
  );
}
