/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { FastForwardIcon } from '@heroicons/react/solid'
import PRIVACYNOTICE from './PRIVACYNOTICE.pdf'
import EULA from './EULA.pdf'
const faqs = [
  {
    question: "Privacy Policy",
    answer: "policy"
  },
  {
    question: "End User Licence Agreement",
    answer: "policy"
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Policies() {
   
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 style={{color:'#242424', fontFamily:'Open Sans',fontWeight:900, fontSize:'2.5rem', textAlign:'center'}}>Frequently asked questions</h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span style={{color:'#242424', fontFamily:'Open Sans',fontWeight:600, textAlign:'center'}}>{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" style={{color:'#242424', fontFamily:'Open Sans',fontWeight:200,}}>
                        {faq.question === "Privacy Policy" ? (<a href={PRIVACYNOTICE} download>You may download our privacy policy <span style={{color:'#60a5fa', fontFamily:'Open Sans', fontWeight:200}}>here</span></a>) : 
                        ( null)}
                    
                    {faq.question === "End User Licence Agreement" ? (<a href={EULA} download>You may download our end user licence agreement <span style={{color:'#60a5fa', fontFamily:'Open Sans', fontWeight:200}}>here</span></a>) : 
                        (null)}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
