import { Fragment, useCallback } from "react";
import Hero from "./components/Hero";
import Policies from "./components/Policies";
import Footer from "./components/Footer";
import Features from "./components/Features";
import Modal from "./components/Modal";

function App() {
  return (
    <div >
      <Hero />
      <Features/>
      <Modal/>
      <Policies />
      <Footer />
    </div>
  );
}

export default App;
