/* This example requires Tailwind CSS v2.0+ */
import {
  ChatAltIcon,
  DocumentReportIcon,
  HeartIcon,
  InboxIcon,
  PencilAltIcon,
  ReplyIcon,
  TrashIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import timelyMessage from "../assets/clock.png";
import one2One from '../assets/onetoone.png'
import encryption from '../assets/encryption.png'
import useranonymous from '../assets/useranonymous.png'
import metadaata from '../assets/metadaata.png'
import message from '../assets/message.png'
import fast from '../assets/fast.png'
import ephe from '../assets/ephe.png'


const features = [
  {
    name: "Anonymous",
    description:
      "All the messages are anonymous and cannot be tracked back to the sender. You do not require to be logged in to send messages.",
    icon: useranonymous,
  },
  {
    name: "Encryption",
    description:
      "The tunnels are end to end encrypted using FADE protocol. Encryption salt is generated on the fly and is not stored on the server.",
    icon: encryption,
  },
  {
    name: "Ephemeral",
    description:
      "The messaging tunnels are temporary and are destroyed in 10 minutes. The tunnel is not stored on the server and all the messages in cache are destroyed along with the tunnel.",
    icon: timelyMessage,
  },
  {
    name: "MetaData",
    description:
      "We do not track or store any meta data about you such as your name, email, phone number, etc. We do not store any of your data or meta-data. We do not track you!",
    icon: metadaata,
  },
  {
    name: "Timed Tunnels",
    description:
      "Messages are sent via a secure tunnel. The tunnel and all the messages are NATIVELY deleted. Deleted means destroyed without any backup.",
    icon: timelyMessage,
  },
  {
    name: "Messages",
    description:
      "The messages are encrypted using FADE protocol. Encryption salt is generated with a random 32 character seed and randomised algorithmic complexity.",
    icon: message,
  },
  {
    name: "Lightning Fast",
    description:
      "Fade is lightning fast. Messages are sent over a secure tunnel in under 100 milliseconds.",
    icon: fast,
  },
  {
    name: "One to One",
    description:
      "The tunnels cannot be shared between multiple users. You can only send messages to one user at a time.",
    icon: one2One,
  },
];

export default function Features() {
  return (
    <div style={{ backgroundColor: "#242424" }}>
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2
          style={{
            color: "#fff",
            fontFamily: "Open Sans",
            fontWeight: 900,
            fontSize: "3rem",
          }}
        >
          The only anonymous and fully untracked messaging app!
        </h2>
        <p style={{ color: "#fff", fontFamily: "Open Sans" }}>
          The messages are end-to-end encrypted, ephemeral, and untracked. 
        </p>
        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name}>
              <div>
                <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  {/* <feature.icon className="h-6 w-6 text-white" aria-hidden="true" /> */}

                  <img className="h-6 w-6 text-white" aria-hidden="true" src={feature.icon} alt="" />
                </span>
              </div>
              <div className="mt-6">
                <h3
                  style={{
                    color: "#fff",
                    fontFamily: "Open Sans",
                    fontWeight: 600,
                  }}
                >
                  {feature.name}
                </h3>
                <p style={{ color: "#fff", fontFamily: "Open Sans" }}>
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
